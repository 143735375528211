import React, { useEffect } from "react"
import { Badge, Col, Modal, ModalHeader, Row, Spinner } from "reactstrap"

import { convertDate } from "pages/add-paper/utils/convertDate"
import { getDocUsage } from "store/actions"
import { useDispatch, useSelector } from "react-redux"

const UsageDetails = props => {
  const { modal, toggle, usageData } = props
  const dispatch = useDispatch()
  const { docUsageData, docUsageLoading } = useSelector(
    state => state.DocumentsList
  )

  useEffect(() => {
    console.log(usageData)
  }, [usageData])

  const data = {
    "document_id": "asd3fsafsdaf4",
    "created_at": "04 Nov,2024 01:02:11",
    "created_by": "Superadmin",
    "assigned_status": "assinged",
    "usage_status": null,
    "used_by": 1,
    "usage_date": null
}

  return (
    <Modal isOpen={modal} toggle={toggle} size="md">
      <ModalHeader toggle={toggle} tag="h4">
        Doc. {usageData && usageData.document_id} Usage Details
      </ModalHeader>
      {docUsageLoading ? (
        <div className="text-center m-auto p-3">
          <Spinner className="ms-2" color="primary" />
        </div>
      ) : (
        <Row>
          <Col lg="12" className="mt-2 mx-3">
            <Row>
              <Col lg="6">
                <div className="flex justify-content-between mb-3">
                  <span style={{ fontWeight: "600" }}>Usage Status: </span>
                  <span style={{ fontWeight: "500" }}>
                    <Badge
                      className={`p-2 
                             ${
                               usageData.usage_status
                                 ? "bg-success"
                                 : "bg-danger"
                             }`}
                    >
                      {usageData.usage_status ? "used" : "Not used"}
                    </Badge>
                  </span>
                </div>
                <div className="flex justify-content-between mb-3">
                  <span style={{ fontWeight: "600" }}>Usage Date: </span>
                  <span style={{ fontWeight: "500" }}>
                    {usageData &&
                      (usageData.usage_date !== null
                        ? usageData.usage_date
                        : "")}
                  </span>
                </div>
              </Col>
              <Col lg="6">
                <div className="flex justify-content-between mb-3">
                  <span style={{ fontWeight: "600" }}>used By: </span>
                  <span style={{ fontWeight: "500" }}>{usageData.used_by_name}</span>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </Modal>
  )
}

export default UsageDetails
