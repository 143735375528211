import { call, put, takeEvery, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER_DATA, LOGOUT_USER, REFRESH_TOKEN } from "./actionTypes"
import {
  loginError,
  loginSuccessData,
  logoutUserSuccess,
  refreshTokenSuccess,
  refreshTokenError,
} from "./actions"

import { postLogin, postRefreshToken } from "../../helpers/backend_helper"

function* loginUserData({ payload: { user, history } }) {
  // Buffalo Login Code

  let userData = {
    email: user.email,
    password: user.password,
  }
  try {
    const response = yield call(postLogin, userData)
    console.log(response)
    if (response.status === 200) {
      if (response.data.error) {
        console.log(response.data.error)
        yield put(loginError(response.data.error))
      } else if (response.data.user) {
        localStorage.setItem("token", response.data.token.access_token)
        yield put(loginSuccessData(response.data.user))
        history("/invoices")
      }
    }
    // localStorage.setItem("authUser", JSON.stringify(response.data.user.employee))
    // localStorage.setItem("shiftID", response.data.rs.shiftID)
    // yield put(loginSuccessData(response.data.rs.employee))
  } catch (error) {
    yield put(loginError(error))
  }
}
function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")
    localStorage.removeItem("token")

    // if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
    //   const response = yield call(fireBaseBackend.logout)
    yield put(logoutUserSuccess(history))
    // }
    history.push("/login")
  } catch (error) {
    // yield put(apiError(error))
  }
}

function* refreshToken() {
  try {
    const response = yield call(postRefreshToken)
    console.log(response)
    localStorage.setItem("token", response.token.access_token)
    yield put(refreshTokenSuccess(response.user))
  } catch (error) {
    yield put(refreshTokenError(error))
  }
}
function* loginSaga() {
  yield takeEvery(LOGIN_USER_DATA, loginUserData)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(REFRESH_TOKEN, refreshToken)
}

export default loginSaga
