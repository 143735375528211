import {
  LOGIN_USER_DATA,
  LOGIN_SUCCESS_DATA,
  LOGOUT_USER_CLEAR,
  LOGOUT_USER_SUCCESS_CLEAR,
  LOGIN_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
} from "./actionTypes"

export const loginUserData = (user, history) => {
  return {
    type: LOGIN_USER_DATA,
    payload: { user, history },
  }
}

export const loginSuccessData = user => {
  return {
    type: LOGIN_SUCCESS_DATA,
    payload: user,
  }
}

export const logoutUserClear = history => {
  return {
    type: LOGOUT_USER_CLEAR,
    payload: { history },
  }
}

export const logoutUserSuccessClear = () => {
  return {
    type: LOGOUT_USER_SUCCESS_CLEAR,
    payload: {},
  }
}

export const loginError = error => {
  return {
    type: LOGIN_ERROR,
    payload: error,
  }
}

export const logoutUser = history => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  }
}

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  }
}

export const refreshToken = () => {
  return {
    type: REFRESH_TOKEN,
  }
}

export const refreshTokenSuccess = data => {
  return {
    type: REFRESH_TOKEN_SUCCESS,
    payload: data,
  }
}

export const refreshTokenError = error => {
  return {
    type: REFRESH_TOKEN_ERROR,
    payload: error,
  }
}
