import React, { useState, useEffect } from "react"

import { Button, Card, CardBody, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"

import CreatePaper from "pages/add-paper/createPaper"
import CreatePaperModal from "./CreatePaperModal"
import DocumentsList from "pages/add-paper/documentsList"
import { useDispatch, useSelector } from "react-redux"
import { getUsersList, getDocumentsList, getUnusedDoc } from "store/actions"

const Paper = () => {
  document.title = "Dragon | add paper id"
  const dispatch = useDispatch()
  const [modal, setModal] = useState(false)
  const [docList, setDocList] = useState([])

  const { documentsList: fetchedDocumentsList, addNewDocumentres } =
    useSelector(state => state.DocumentsList)

  const toggle = () => setModal(prevState => !prevState)

  useEffect(() => {
    dispatch(getUsersList())
    dispatch(getDocumentsList())
    dispatch(getUnusedDoc())
  }, [])
  useEffect(() => {
    addNewDocumentres !== "" && dispatch(getDocumentsList())
  }, [addNewDocumentres])

  useEffect(() => {
    if (fetchedDocumentsList && fetchedDocumentsList.data) {
      fetchedDocumentsList.data.length && setDocList(fetchedDocumentsList.data)
    }
  }, [fetchedDocumentsList])

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Invoices" breadcrumbItem="Add Document Id" />
        <Row>
          <Card>
            <CardBody className="d-flex flex-row flex-wrap align-items-start gap-2 justify-content-end">
              {/* <div>
                <Button
                  color="primary"
                  className="btn-rounded"
                  onClick={() => {
                    toggle()
                  }}
                >
                  <i className="mdi mdi-plus" /> Add Document
                </Button>
              </div> */}

              {/* <CreatePaper modal={modal} toggle={toggle} /> */}
              <DocumentsList docList={docList} toggle={toggle} />
            </CardBody>
            <CreatePaperModal modal={modal} toggle={toggle} docList={docList} />
          </Card>
        </Row>
      </Container>
    </div>
  )
}

export default Paper
