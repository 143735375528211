import React, { useState, createContext, useEffect } from "react"

const initialTabelData = []
const initialActiveRow = 1

const initialEmpty = "false"

export const PetContext = createContext({
  tabelData: initialTabelData,
  activeRow: initialActiveRow,
  updateTabelDataHandler: data => {},
  updateSourceOfRecordHandler: (rowData, sourceOfRecord) => {},
  updateIsEmptyHandler: () => {},

})

let tabel = []
const PetContextProvider = props => {
  const [tabelData, setTabelData] = useState(initialTabelData)
  const [activeRow, setActiveRow] = useState(initialActiveRow)
  const [isEmpty, setIsEmpty] = useState(initialEmpty)
  const [petReset, setPetReset] = useState(false)

  // update Active row
  useEffect(() => {
    if (tabelData.length && tabelData[activeRow - 1]) {
      if (
        tabelData[activeRow - 1].petId &&
        tabelData[activeRow - 1].petName &&
        tabelData[activeRow - 1].aboveNo
      ) {
        setActiveRow(prevState => prevState + 1)
      }
    }
  }, [tabelData])
  
  const updateTabelDataHandler = data => {
      const updatedTabelData = [...tabel]
    const index = updatedTabelData.findIndex(
      one => one.rowNumber === data.rowNumber
    )

    if (index === -1) {
      updatedTabelData.push(data)
    } else {
      updatedTabelData[index] = data
    }

    setTabelData(updatedTabelData)
    // petTableData
    tabel = updatedTabelData
  }
  const updateIsEmptyHandler = value => {
    setIsEmpty(value)
  }

  const petResetHandler = (state) => {
    setPetReset(state)
    setActiveRow(1)
   }

  return (
    <PetContext.Provider
      value={{
        tabelData,
        activeRow,
        updateTabelDataHandler,
        updateIsEmptyHandler,
        petReset,
        petResetHandler
      }}
    >
      {props.children}
    </PetContext.Provider>
  )
}

export default PetContextProvider