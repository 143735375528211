import React, { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import { Modal, ModalBody, ModalHeader, Col, Card, CardBody  } from "reactstrap"
import TableContainer from "../../components/Common/TableContainer"

const PaymentSummaryModal = props => {
  const { getInvoicePaymentRes, getInvoicePaymentLoading } = useSelector(
    state => state.Invoices
  )


  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "amount",
        accessor: "amount",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "paymentMethod",
        accessor: "paymentMethod",
        filterable: true,
        Cell: cellProps => {return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "refNo",
        accessor: "refNo",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
     
    ],
    []
  )

  const { modal, toggle } = props
  return (
    <Modal isOpen={modal} toggle={toggle} size="xl" fullscreen="lg">
      <ModalHeader toggle={toggle} tag="h4">
        Payment Summary
      </ModalHeader>
      <ModalBody>
        {getInvoicePaymentLoading ? (
          <div className="text-center m-auto">
            <i className="bx bx-loader bx-spin font-size-20 align-middle me-2" />
          </div>
        ) : (
          <Col lg="12">
          <Card>
            <CardBody>
              <TableContainer
                columns={columns}
                data={getInvoicePaymentRes}
                isGlobalFilter={true}
                isAddOptions={false}
                customPageSize={5}
                allListSize={getInvoicePaymentRes.length}
              />
            </CardBody>
          </Card>
        </Col>
        )}
      </ModalBody>
    </Modal>
  )
}

export default PaymentSummaryModal
