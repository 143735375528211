import React, { useContext } from "react"

import Header from "./header"
import PaymentSummary from "pages/add-invoice/notInPrint/paymentSummary"

import { PaymentSummaryContext } from "pages/add-invoice/context/paymentSummaryContext"

const NonPrintableContainer = () => {
  const paymentSummaryCtx = useContext(PaymentSummaryContext)
  const { tabelData, activeRow } = paymentSummaryCtx

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        position: "absolute",
        top: "0",
        left: "0",
      }}
    >
      <div
        style={{
          position: "relative",
          zIndex: 5,
        }}
      >
        <Header />
      </div>
      <div
        style={{
          marginTop: "1150px",
          marginLeft: "1130px",
          position: "relative",
        }}
      >
        <div style={{ position: "absolute", top: "0", left: "0", zIndex: 100 }}>
          {tabelData.length ? (
            tabelData.map((one, index) =>
              index + 1 <= activeRow ? (
                <PaymentSummary key={index} rowData={one} />
              ) : (
                <div key={index}></div>
              )
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  )
}

export default NonPrintableContainer
