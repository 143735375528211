import React, { useEffect, useState, useContext } from "react"
import { TabelContext } from "pages/add-invoice/context/tabelContext"
import PrintModel from "pages/add-invoice/printInvoice/printModel"
import { useDispatch, useSelector } from "react-redux"
import { getUsersList, getDocumentsList } from "store/actions"
import { set } from "store"

const Submit = props => {
  const { handleErr } = props
  const dispatch = useDispatch()
  const [showModal, setShowModal] = useState(false)
  const [errList, setErrList] = useState([])
  const [checkedAllValues, setCheckedAllValues] = useState(false)

  const { documentsList } = useSelector(state => state.DocumentsList)

  const { invoiceData, isEmpty } = useContext(TabelContext)
  
// const obj = {
//   "misRet": 94403937,
//   "salesType": "",
//   "preparedBy": "",
//   "date": "11 Nov, 2024",
//   "time": "12:05:53",
//   "customerName": "",
//   "customerNo": "",
//   "mobile": "",
//   "email": "",
//   "purchaseType": "",
//   "marketPlacePurchased": "",
//   "systemUser": ""
// }
  const handleSubmit = () => {
    setErrList([])
    invoiceData.headerData.preparedBy  === "" && setErrList(prevState => [...prevState, "Prepared By"])
    invoiceData.headerData.customerNo  === "" && setErrList(prevState => [...prevState, "Customer No"])
    invoiceData.headerData.customerName  === "" && setErrList(prevState => [...prevState, "Customer Name"])
    invoiceData.headerData.salesType  === "" && setErrList(prevState => [...prevState, "Sales Type"])
    invoiceData.headerData.purchaseType  === "" && setErrList(prevState => [...prevState, "Purchase Type"])
    invoiceData.headerData.purchaseType  === "market-place-purchased" && invoiceData.headerData.marketPlacePurchased  === "" && setErrList(prevState => [...prevState, "Market Place Purchased"])
    invoiceData.tabelData.length === 0 && setErrList(prevState => [...prevState, "Items Table"])
     setCheckedAllValues(true)
      // toggleModalHandler()
      dispatch(getDocumentsList())
    
  }
  useEffect(() => {
    if (checkedAllValues) {
      errList.length === 0 ? toggleModalHandler() : handleErr(errList)
      setCheckedAllValues(false)
    }
  }, [checkedAllValues])

  const toggleModalHandler = () => {
    setShowModal(prevState => !prevState)
  }
  const close = () => setShowModal(false)

  // useEffect(() => {
  //   documentsList && (documentsList.length === 0 && dispatch(getDocumentsList()))
  // }, [])

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginTop: "10px",
        }}
      >
        <button
          onClick={handleSubmit}
          type="button"
          className="btn btn-primary btn-label mx-1 mb-2"
        >
          <>
            <i className="bx bx-check label-icon"></i> Submit
          </>
        </button>
      </div>
      <PrintModel
        isOpen={showModal}
        toggle={toggleModalHandler}
        printContentRef={props.printContentRef}
        close={close}
      />
    </>
  )
}

export default Submit
