export const LOGIN_USER_DATA = "LOGIN_USER_DATA"
export const LOGIN_SUCCESS_DATA = "LOGIN_SUCCESS_DATA"

export const LOGOUT_USER_CLEAR = "LOGOUT_USER_CLEAR"
export const LOGOUT_USER_SUCCESS_CLEAR = "LOGOUT_USER_SUCCESS_CLEAR"

export const LOGIN_ERROR = "LOGIN_ERROR"

export const LOGOUT_USER = "LOGOUT_USER"
export const LOGOUT_USER_SUCCESS = "LOGOUT_USER_SUCCESS"

export const REFRESH_TOKEN = "REFRESH_TOKEN"
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS"
export const REFRESH_TOKEN_ERROR = "REFRESH_TOKEN_ERROR"