import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { Routes, Route } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"
import Pages401 from "pages/Utility/pages-401"

import "./assets/scss/theme.scss"
import "react-toastify/dist/ReactToastify.css"

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";

import fakeBackend from "./helpers/AuthType/fakeBackend"
import { refreshToken } from "store/actions"

// Activating fake backend
fakeBackend()
const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    case layoutTypes.HORIZONTAL:
      Layout = HorizontalLayout
      break
    default:
      break
  }
  return Layout
}

const App = () => {
  const { layoutType } = useSelector(state => state.Layout)
  const { loginData } = useSelector(state => state.loginUser)
  const [permissions, setPermissions] = React.useState([])

  const Layout = getLayout(layoutType)
  const dispatch = useDispatch()
  useEffect(() => {
    if (localStorage.getItem("token") && loginData === "") {
      dispatch(refreshToken()) // Dispatches RESET_ACCESS_TOKEN to start the token refresh saga
    }
    console.log("loginData", loginData)
    if(loginData !== "") {
      const privilegeArray = Object.entries(loginData.permissions).map(([key, value]) => ({
        // [key]: value,
        PK_Page_ID: value
      }));
      console.log(privilegeArray)
      setPermissions(privilegeArray)
    }
  }, [loginData])

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                {/* <Layout>{route.component}</Layout> */}
                <Layout>
                  {/* {route.component} */}
                  {loginData !== "" &&
                    (permissions.some(
                      privilege => privilege.PK_Page_ID === route.pageId
                    ) ? (
                      route.component
                    ) : (
                      <Pages401 />
                    ))}
                  {/* {loginData !== "" &&
                    Object.keys(loginData.permissions).forEach(key =>
                      loginData.permissions[key] === route.pageId ? (
                        route.component
                      ) : (
                        <Pages401 />
                      )
                    )} */}
                </Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
