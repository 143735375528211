import TableContainer from "components/Common/TableContainer"
import React, { useEffect, useMemo, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Modal,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import classes from "./index.module.css"
import { useDispatch, useSelector } from "react-redux"
import { getInvoiceDetails } from "store/actions"
import { set } from "store"

const InvoiceModal = props => {
  const { modal, toggle, invoiceDetails } = props
  const { invoiceDetail, getInvoiceDetailsLoading } = useSelector(
    state => state.Invoices
  )
  const [invoiceDetailData, setInvoiceDetailData] = useState("")
  const [transaction, setTransactions] = useState([])
  const dispatch = useDispatch()

  useEffect(() => {
    invoiceDetail !== "" && setInvoiceDetailData(invoiceDetail)
    invoiceDetail !== "" && setTransactions(invoiceDetail.transaction_lines)
  }, [invoiceDetail])
  useEffect(() => {
    if (invoiceDetails !== "") {
      dispatch(getInvoiceDetails(invoiceDetails))
    }
  }, [invoiceDetails])

  const columns = useMemo(
    () => [
      {
        Header: "Row Number",
        accessor: "id",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Item Code",
        accessor: "item_code",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Description",
        accessor: "description",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Pet Name",
        accessor: "name",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Pet Id",
        accessor: "pet_id",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Unit Price",
        accessor: "unit_price",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Qty",
        accessor: "quantity",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Total Price",
        accessor: "total_price",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Discount",
        accessor: "discount",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Line Total",
        accessor: "line_total",
        filterable: true,
        disableFilters: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
    ],
    []
  )

  return (
    <Modal isOpen={modal} toggle={toggle} size="lg" fullscreen="lg">
      <ModalHeader toggle={toggle} tag="h4">
        Invoice No. {invoiceDetails ? invoiceDetails : ""}
      </ModalHeader>
      {getInvoiceDetailsLoading ? (
        <div className="text-center m-auto p-3">
          <Spinner className="ms-2" color="primary" />
        </div>
      ) : (
        <div>
          <div className="table-responsive">
            <Table
              className={`table mb-0 table-bordered ${classes.tableSmall}`}
            >
              <tbody>
                <tr>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    Sales Type:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.sales_type
                      ? invoiceDetailData.sales_type
                      : ""}
                  </td>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    Prepared By:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.prepared_by
                      ? invoiceDetailData.prepared_by
                      : ""}
                  </td>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    System user:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.systemUser
                      ? invoiceDetailData.systemUser
                      : ""}
                  </td>
                </tr>

                <tr>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    Cust. No:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.customer_number
                      ? invoiceDetailData.customer_number
                      : ""}
                  </td>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    Cust. Name:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.customer_name
                      ? invoiceDetailData.customer_name
                      : ""}
                  </td>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    Mobile:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.Customer_phone
                      ? invoiceDetailData.Customer_phone
                      : ""}
                  </td>
                </tr>

                <tr>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    Notes:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.notes
                      ? invoiceDetailData.notes
                      : ""}
                  </td>

                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    Email:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.email
                      ? invoiceDetailData.email
                      : ""}
                  </td>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    MemberShip:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.membership
                      ? invoiceDetailData.membership
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    Date:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.date ? invoiceDetailData.date : ""}
                  </td>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    time:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.time ? invoiceDetailData.time : ""}
                  </td>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    updated at:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.updated_at
                      ? invoiceDetailData.updated_at
                      : ""}
                  </td>
                </tr>
                <tr>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    Purch. type:
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.purchase_type
                      ? invoiceDetailData.purchase_type
                      : ""}
                  </td>
                  <th scope="row" className={`text-capitalize ${classes.th}`}>
                    {invoiceDetailData.market_Place_Purchased &&
                      "Market place:"}
                  </th>
                  <td className={`bg-white ${classes.td}`}>
                    {invoiceDetailData.market_Place_Purchased &&
                    invoiceDetailData.market_Place_Purchased
                      ? invoiceDetailData.market_Place_Purchased
                      : ""}
                  </td>
                  <th
                    scope="row"
                    className={`text-capitalize ${classes.th}`}
                  ></th>
                  <td className={`bg-white ${classes.td}`}>
                    {/* {invoiceDetails ? invoiceDetails.updated_at : ""} */}
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <TableContainer
                    columns={columns}
                    data={transaction}
                    isGlobalFilter={true}
                    isAddOptions={false}
                    customPageSize={5}
                    allListSize={transaction ? transaction.length : 0}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      )}
    </Modal>
  )
}

export default InvoiceModal
