import {
  GET_INVOICES,
  GET_INVOICES_FAIL,
  GET_INVOICES_SUCCESS,
  GET_INVOICE_DETAIL,
  GET_INVOICE_DETAIL_SUCCESS,
  GET_INVOICE_DETAIL_FAIL,
  GET_INVOICES_STATUS,
  GET_INVOICES_STATUS_SUCCESS,
  GET_INVOICES_STATUS_FAIL,
  POST_INVOICES_STATUS,
  POST_INVOICES_STATUS_SUCCESS,
  POST_INVOICES_STATUS_FAIL,
  GET_TICKET_STATUS,
  GET_TICKET_STATUS_SUCCESS,
  GET_TICKET_STATUS_FAIL,
  POST_TICKET_STATUS,
  POST_TICKET_STATUS_SUCCESS,
  POST_TICKET_STATUS_FAIL,
  GET_INVOICE_PAYMENT_SUMMARY,
  GET_INVOICE_PAYMENT_SUMMARY_SUCCESS,
  GET_INVOICE_PAYMENT_SUMMARY_FAIL,
  POST_INVOICE,
  POST_INVOICE_SUCCESS,
  POST_INVOICE_FAIL,
  POST_INVOICE_RESET,
} from "./actionTypes"

const INIT_STATE = {
  invoices: [],
  getInvoiceLoading: false,
  getInvoiceErr: {},

  getInvoiceDetailsLoading: false,
  invoiceDetail: "",
  invoiceDetailsErr: {},

  invoicesStatus: [],
  getInvoicesStatusLoading: false,
  getInvoicesStatusError: {},

  postInvoiceSatusRes: "",
  postInvoiceStatusLoading: false,
  postInvoiceError: {},

  ticketStatus: [],
  getTicketStatusLoading: false,
  getTicketStatusError: {},

  postTicketLoading: false,
  postTicketStatusRes: "",
  postTicketError: {},

  getInvoicePaymentLoading: false,
  getInvoicePaymentRes: [],
  getInvoicePaymentError: {},

  postInvoiceLoading: false,
  postInvoiceRes: "",
  postInvoiceError: {},
}

const Invoices = (state = INIT_STATE, action) => {
  switch (action.type) {
    // get all invoices
    case GET_INVOICES:
      return {
        ...state,
        getInvoiceLoading: true,
        getInvoiceErr: {},
      }
    case GET_INVOICES_SUCCESS:
      return {
        ...state,
        invoices: action.payload,
        getInvoiceLoading: false,
        getInvoiceErr: {},
      }

    case GET_INVOICES_FAIL:
      return {
        ...state,
        getInvoiceErr: action.payload,
        getInvoiceLoading: false,
      }

    // get invoice detail
    case GET_INVOICE_DETAIL:
      return {
        ...state,
        getInvoiceDetailsLoading: true,
        invoiceDetailsErr: {},
      }
    case GET_INVOICE_DETAIL_SUCCESS:
      return {
        ...state,
        invoiceDetail: action.payload,
        getInvoiceDetailsLoading: false,
        invoiceDetailsErr: {},
      }

    case GET_INVOICE_DETAIL_FAIL:
      return {
        ...state,
        invoiceDetailsErr: action.payload,
        getInvoiceDetailsLoading: false,
      }

    // get List of invoices status

    case GET_INVOICES_STATUS:
      return {
        ...state,
        getInvoicesStatusLoading: true,
        getInvoicesStatusError: {},
      }

    case GET_INVOICES_STATUS_SUCCESS:
      return {
        ...state,
        invoicesStatus: action.payload,
        getInvoicesStatusLoading: false,
        getInvoicesStatusError: {},
      }

    case GET_INVOICES_STATUS_FAIL:
      return {
        ...state,
        getInvoicesStatusError: action.payload,
        getInvoicesStatusLoading: false,
      }

    // Edit invoice status
    case POST_INVOICES_STATUS:
      return {
        ...state,
        postInvoiceStatusLoading: true,
        postInvoiceError: {},
        postInvoiceSatusRes: "",
      }

    case POST_INVOICES_STATUS_SUCCESS:
      return {
        ...state,
        postInvoiceSatusRes: action.payload,
        postInvoiceStatusLoading: false,
        postInvoiceError: {},
      }

    case POST_INVOICES_STATUS_FAIL:
      return {
        ...state,
        postInvoiceError: action.payload,
        postInvoiceStatusLoading: false,
      }

    // get list of ticket status

    case GET_TICKET_STATUS:
      return {
        ...state,
        getTicketStatusLoading: true,
      }

    case GET_TICKET_STATUS_SUCCESS:
      return {
        ...state,
        ticketStatus: action.payload,
        getTicketStatusLoading: false,
      }

    case GET_TICKET_STATUS_FAIL:
      return {
        ...state,
        getTicketStatusError: action.payload,
        getTicketStatusLoading: false,
      }

    // edit ticket status
    case POST_TICKET_STATUS:
      return {
        ...state,
        postTicketLoading: true,
        postTicketStatusRes: "",
      }

    case POST_TICKET_STATUS_SUCCESS:
      return {
        ...state,
        postTicketStatusRes: action.payload,
        postTicketLoading: false,
        postTicketError: {},
      }

    case POST_TICKET_STATUS_FAIL:
      return {
        ...state,
        postTicketError: action.payload,
        postTicketLoading: false,
      }

    case GET_INVOICE_PAYMENT_SUMMARY:
      return {
        ...state,
        getInvoicePaymentLoading: true,
        getInvoicePaymentError: {},
        getInvoicePaymentRes: [],
      }

    case GET_INVOICE_PAYMENT_SUMMARY_SUCCESS:
      return {
        ...state,
        getInvoicePaymentRes: action.payload,
        getInvoicePaymentLoading: false,
        getInvoicePaymentError: {},
      }

    case GET_INVOICE_PAYMENT_SUMMARY_FAIL:
      return {
        ...state,
        getInvoicePaymentLoading: false,
        getInvoicePaymentRes: [],
        getInvoicePaymentError: action.payload,
      }

    case POST_INVOICE:
      return {
        ...state,
        postInvoiceLoading: true,
        postInvoiceError: {},
        postInvoiceRes: "",
      }

    case POST_INVOICE_SUCCESS:
      return {
        ...state,
        postInvoiceRes: action.payload,
        postInvoiceLoading: false,
        postInvoiceError: {},
      }

    case POST_INVOICE_FAIL:
      return {
        ...state,
        postInvoiceError: action.payload,
        postInvoiceLoading: false,
      }

    case POST_INVOICE_RESET:
      return {
        ...state,
        postInvoiceLoading: false,
        postInvoiceError: {},
        postInvoiceRes: "",
      }

    default:
      return state
  }
}

export default Invoices
