import { takeEvery, put, call } from "redux-saga/effects"

import {
  GET_DOCUMENTS_LIST,
  GET_USERS_LIST,
  POST_DOCUMENT_ASSIGNTO,
  GET_DOCUMENT_ASSIGN_DETAILS,
  POST_NEW_DOCUMENT,
  GET_DOC_USAGE,
  GET_UNUSED_DOC
} from "./actionTypes"

import {
  getDocumentsListSuccess,
  getDocumentsListFail,
  getUsersListSuccess,
  getUsersListFail,
  postDocumentAssignToSuccess,
  postDocumentAssignToFail,
  getDocumentAssignDetailsSuccess,
  getDocumentAssignDetailsFail,
  postNewDocumentSuccess,
  postNewDocumentFail,
  getDocUsageSuccess,
  getDocUsageFail,
  getUnusedDocSuccess,
  getUnusedDocFail
} from "./actions"

import { addDocuments, getDocuments, postUserToDoc, getDocUsageDetails, getUsers, getDocAssignDetails, getUnusedDocument } from "helpers/backend_helper"
import { toast } from "react-toastify"

const responseUser = [
  { name: "SuperAdmin", id: 1 },
  { name: "Admin", id: 2 },
  { name: "subAdmin", id: 3 },
  { name: "manager", id: 4 },
  { name: "moderator", id: 5 },
]


const docAssignDetailsList = id => {
  if (id === 'x-0001') {
    return [
      {
        assignedTo: "moderator",
        assignedBy: "admin",
        assignedDate: "2024-02-26T09:00:00.000Z",
      },
      {
        assignedTo: "manager",
        assignedBy: "admin",
        assignedDate: "2024-02-26T09:00:00.000Z",
      },
    ]
  } else if (id === 'x-0002') {
    return [
      {
        assignedTo: "moderator",
        assignedBy: "admin",
        assignedDate: "2024-02-26T09:00:00.000Z",
      },
      {
        assignedTo: "manager",
        assignedBy: "admin",
        assignedDate: "2024-02-26T09:00:00.000Z",
      },
    ]
  } else {
    return []
  }
}

function* fetchDocumentsList() {
  try {
    const response = yield call(getDocuments)
  //   const response = {
  //     "data": [
  //         {
  //             "document_id": "asd34",
  //             "created_at": "04 Nov,2024 11:22:27",
  //             "created_by": "Superadmin",
  //             "assigned_status": "not assigned",
  //             "usage_status": null,
  //             "used_by": null,
  //             "usage_date": null,
  //             "invoice_serial": "29070320",
  //             "invoice_id": 2,
  //             "price": "73.00",
  //             "assigned_to": 106
  //         },
  //         {
  //             "document_id": "asd3fsdaf4",
  //             "created_at": "04 Nov,2024 12:54:31",
  //             "created_by": "Superadmin",
  //             "assigned_status": "not assigned",
  //             "usage_status": null,
  //             "used_by": null,
  //             "usage_date": null,
  //             "invoice_serial": "29070320",
  //             "invoice_id": 2,
  //             "price": "73.00",
  //             "assigned_to": 84
  //         },
  //         {
  //             "document_id": "asd3fsafsdaf4",
  //             "created_at": "04 Nov,2024 01:02:11",
  //             "created_by": "Superadmin",
  //             "assigned_status": "assinged",
  //             "usage_status": null,
  //             "used_by": null,
  //             "usage_date": null,
  //             "invoice_serial": "29070320",
  //             "invoice_id": 2,
  //             "price": "73.00",
  //             "assigned_to": 106
  //         },
  //         {
  //             "document_id": "x01-00",
  //             "created_at": "13 Nov,2024 10:24:00",
  //             "created_by": "Superadmin",
  //             "assigned_status": "not assigned",
  //             "usage_status": "used",
  //             "used_by": null,
  //             "usage_date": null,
  //             "invoice_serial": "29070320",
  //             "invoice_id": 2,
  //             "price": "73.00",
  //             "assigned_to": 55
  //         },
  //         {
  //             "document_id": "x02-001",
  //             "created_at": "10 Dec,2024 11:26:40",
  //             "created_by": "Superadmin",
  //             "assigned_status": "not assigned",
  //             "usage_status": "used",
  //             "used_by": null,
  //             "usage_date": null,
  //             "invoice_serial": "29070320",
  //             "invoice_id": 2,
  //             "price": "73.00",
  //             "assigned_to": 106
  //         }
  //     ]
  // }
    console.log(response)
    yield put(getDocumentsListSuccess(response))
  } catch (error) {
    yield put(getDocumentsListFail(error))
  }
}

function* fetchUsersList() {
  try {
    const response = yield call(getUsers)
    yield put(getUsersListSuccess(response))
  } catch (error) {
    yield put(getUsersListFail(error))
  }
}

function* postDocAssignTo({ payload: data }) {
  try {
    const response = yield call(postUserToDoc, data)
    yield put(postDocumentAssignToSuccess(response))
  } catch (error) {
    yield put(postDocumentAssignToFail(error))
  }
}

function* fetchDocAssignDetails({ payload: data }) {
  try {
    // const res = docAssignDetailsList(data)
    console.log(data)
    const response = yield call(getDocAssignDetails, data)
    console.log(response)
    yield put(getDocumentAssignDetailsSuccess(response))
  } catch (error) {
    yield put(getDocumentAssignDetailsFail(error))
  }
}

function* postNewDocumentData({ payload: data }) {
  try {
    const res = 1
    const response = yield call(addDocuments, data)
    console.log(response)
    if(response === undefined){
      yield put(postNewDocumentFail("data is invalid"))
    }else if(response.message) {
      yield put(postNewDocumentSuccess(response))
      toast.dismiss()
      toast.success(response.message)
    } else if(response.status === 422) {
      yield put(postNewDocumentFail(response))
      toast.dismiss()
      toast.error(response.data.message)
    }
  } catch (error) {
    yield put(postDocumentAssignToFail(error))
  }
}

function* onGetDocUsage({ payload: data }) {
  try {
    const response = yield call(getDocUsageDetails, data)
    console.log(response)
    yield put(getDocUsageSuccess(response))
  } catch (error) {
    yield put(getDocUsageFail(error))
  }
}

function* onGetUnusedDoc() {
  try {
    const response = yield call(getUnusedDocument)
    console.log(response)
    yield put(getUnusedDocSuccess(response))
  } catch (error) {
    yield put(getUnusedDocFail(error))
  }
}

function* documentListSaga() {
  yield takeEvery(GET_DOCUMENTS_LIST, fetchDocumentsList)
  yield takeEvery(GET_USERS_LIST, fetchUsersList)
  yield takeEvery(POST_DOCUMENT_ASSIGNTO, postDocAssignTo)
  yield takeEvery(GET_DOCUMENT_ASSIGN_DETAILS, fetchDocAssignDetails)
  yield takeEvery(POST_NEW_DOCUMENT, postNewDocumentData)
  yield takeEvery(GET_DOC_USAGE, onGetDocUsage)
  yield takeEvery(GET_UNUSED_DOC, onGetUnusedDoc)
}

export default documentListSaga
