import React, { useEffect, useContext } from "react"
import classes from "../index.module.scss"
import * as Yup from "yup"
import { useFormik } from "formik"
import { FormFeedback, Input } from "reactstrap"

import { PaymentSummaryContext } from "pages/add-invoice/context/paymentSummaryContext"

const methodOptions = ["Card", "Cash", "Cheque", "Bank Transfer"]

const convertDate = () => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const covertedDate = new Date()

  const date =
    ("0" + covertedDate.getDate()).slice(-2) +
    " " +
    months[covertedDate.getMonth()] +
    ", " +
    covertedDate.getFullYear()

  const time =
    ("0" + covertedDate.getHours()).slice(-2) +
    ":" +
    ("0" + covertedDate.getMinutes()).slice(-2) +
    ":" +
    ("0" + covertedDate.getSeconds()).slice(-2)

  return { date, time }
}

const SingleTabelRow = props => {
  const paymentSummaryCtx = useContext(PaymentSummaryContext)
  const {
    updateTabelDataHandler,
    activeRow,
    paymentReset,
    paymentResetHandler,
  } = paymentSummaryCtx

  const { rowNumber, index, topTabelRowsData } = props

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      rowNumber: props.rowNumber,
      // aboveNo: "",
      // petName: "",
      // petId: "",
      recivedNow: false,
      amount: "",
      method: "",
      date: "",
      ref: "",
      sourceOfRecord: "",
    },
    validationSchema: Yup.object({
      //   RecivedNow: Yup.bool().required("Please Enter Recived Now"),
      //   amount: Yup.number().required("Please Enter Amount"),
      //   method: Yup.string().required("Please Enter Method"),
      //   date: Yup.string().required("Please Enter Date"),
    }),
    onSubmit: values => {
      //
    },
  })

  useEffect(() => {
    if (validation.values.recivedNow) {
      const now = new Date()
      const year = now.getFullYear().toString()
      const month = (now.getMonth() + 1).toString().padStart(2, "0")
      const day = now.getDate().toString().padStart(2, "0")
      const dateString = `${year}-${month}-${day}`
      validation.setFieldValue("date", dateString)
    } else {
      validation.setFieldValue("date", "")
    }
  }, [validation.values.recivedNow])

  // Bearer token to  parent
  useEffect(() => {
    let empty = false

    updateTabelDataHandler({
      rowNumber: validation.values.rowNumber,
      recivedNow: validation.values.recivedNow,
      amount: validation.values.amount,
      method: validation.values.method,
      date: validation.values.date,
      ref: validation.values.ref,
      sourceOfRecord: validation.values.sourceOfRecord,
    })
  }, [validation.values])

  useEffect(() => {
    if (paymentReset) {
      validation.resetForm()
      const { date, time } = convertDate()
      validation.setFieldValue("date", date)
      validation.setFieldValue("time", time)
    }
    paymentResetHandler(false)
  }, [paymentReset])

  return (
    <div style={{ display: "flex", paddingTop: "12px" }}>
      {/* <div style={{ width: "30px", marginLeft: "53px" }}>
        <span className={classes.transparent_input}>
          {validation.values.rowNumber}
        </span>
      </div>
      <div style={{ width: "80px", marginLeft: "-5px" }}>
        <select
          id="aboveNo"
          name="aboveNo"
          className={`${classes.transparent_input} ${
            index + 1 > activeRow && "opacity-0"
          }`}
          style={{ width: "80px" }}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          disabled={index + 1 > activeRow}
        >
          <option value="">Select ...</option>
          {topTabelRowsData && topTabelRowsData.length ? (
            topTabelRowsData.map((one, index) => (
              <option key={index} value={one.rowNumber}>
                {one.rowNumber}
              </option>
            ))
          ) : (
            <></>
          )}
        </select>
      </div>
      <div style={{ width: "130px", marginLeft: "15px" }}>
        <Input
          id="petName"
          name="petName"
          type="text"
          className={classes.transparent_input}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.petName || ""}
          disabled={index + 1 > activeRow}
          invalid={
            validation.touched.petName && validation.errors.petName
              ? true
              : false
          }
        />
      </div>
      <div style={{ width: "160px", marginLeft: "20px" }}>
        <Input
          id="petId"
          name="petId"
          type="text"
          className={classes.transparent_input}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.petId || ""}
          disabled={index + 1 > activeRow}
          invalid={
            validation.touched.petId && validation.errors.petId ? true : false
          }
        />
      </div> */}
      <div style={{ marginLeft: "13px" }}>
        <Input
          type="checkbox"
          className="form-check-Input"
          style={{ width: "26px", height: "26px", marginLeft: "47px" }}
          id="recivedNow"
          name="recivedNow"
          checked={validation.values.recivedNow}
          disabled={index + 1 > activeRow}
          onChange={validation.handleChange}
        />
      </div>
      <div style={{ width: "120px", marginLeft: "45px" }}>
        <Input
          id="amount"
          name="amount"
          type="number"
          className={classes.transparent_input}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.amount || ""}
          disabled={index + 1 > activeRow}
          invalid={
            validation.touched.amount && validation.errors.amount ? true : false
          }
        />
      </div>
      <div style={{ width: "120px", marginLeft: "10px" }}>
        {validation.values.amount ? (
          <select
            id="method"
            name="method"
            className={`${classes.transparent_input} ${
              index + 1 > activeRow && "opacity-0"
            }`}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            disabled={index + 1 > activeRow}
          >
            <option value="">Select ...</option>
            {methodOptions.map((one, index) => (
              <option key={index} value={one}>
                {one}
              </option>
            ))}
          </select>
        ) : (
          <></>
        )}
      </div>
      <div style={{ width: "130px", marginLeft: "1px" }}>
        <Input
          id="date"
          name="date"
          type="date"
          className={`${classes.transparent_input} ${
            index + 1 > activeRow && "opacity-0"
          }`}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.date || ""}
          disabled={index + 1 > activeRow}
          invalid={
            validation.touched.date && validation.errors.date ? true : false
          }
          readOnly={validation.values.recivedNow}
        />
      </div>
      <div style={{ width: "180px", marginLeft: "-5px" }}>
        <Input
          id="ref"
          name="ref"
          type="number"
          className={classes.transparent_input}
          onChange={validation.handleChange}
          onBlur={validation.handleBlur}
          value={validation.values.ref || ""}
          disabled={index + 1 > activeRow}
          invalid={
            validation.touched.ref && validation.errors.ref ? true : false
          }
          readOnly={validation.values.recivedNow}
        />
      </div>
    </div>
  )
}

export default SingleTabelRow
