import React, { useCallback, useEffect, useMemo, useState } from "react"
import TableContainer from "../../components/Common/TableContainer"
import {
  Col,
  Row,
  Card,
  CardBody,
  Badge,
  Button,
  UncontrolledTooltip,
  Spinner,
} from "reactstrap"
import { Link } from "react-router-dom"
import { documentsListDummyData } from "pages/data"

import AssignDetails from "pages/add-paper/assignDetails"
import UsageDetails from "pages/add-paper/usageDetails"
import UpdateAssignStatus from "pages/add-paper/updateAssignStatus"
import { convertDate } from "pages/add-paper/utils/convertDate"
import { useDispatch, useSelector } from "react-redux"
import { usageListDummyData } from "pages/data"
import { getDocumentAssignDetails } from "store/add-paper/actions"
import InvoiceModal from "pages/invoices-list/invoiceModal"
// documentsList

const DocumentsList = props => {
  const { docList, toggle } = props
  const dispatch = useDispatch()

  const [assignModel, setAssignModel] = useState(false)
  const [usageModel, setUsageModel] = useState(false)
  const [assignData, setAssignData] = useState({})
  const [usageData, setUsageData] = useState("")
  const [showSpecificInvoice, setShowSpecificInvoice] = useState(false)
  const [invoiceDetails, setInvoiceDetails] = useState("")

  const toggleAssignModel = () => setAssignModel(prevState => !prevState)
  const toggleUsageModel = () => setUsageModel(prevState => !prevState)

  const toggleShowSpecificInvoice = () => {
    setShowSpecificInvoice(prevState => !prevState)
  }

  const { getDocumentsLoading } = useSelector(state => state.DocumentsList)

  const dispatchaAssignDetails = id => {
    dispatch(getDocumentAssignDetails(id))
  }

  const columns = useMemo(
    () => [
      {
        Header: "Doc. Id",
        accessor: "document_id",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Created At",
        accessor: "created_at",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value ? convertDate(cellProps.value) : ""}</>
        },
      },
      {
        Header: "Created By",
        accessor: "created_by",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Assign",
        accessor: "assigned_status",
        filterable: true,
        Cell: cellProps => {
          const {  assigned_to } = cellProps.row.original
          return (
            <>
              <Badge
                className={`p-2
                             ${assigned_to !== null ? "bg-success" : "bg-danger"}`}
              >
                {assigned_to !== null ? "assinged" : "Not Assigned"}
              </Badge>
            </>
          )
        },
      },
      {
        // UpdateAssignStatus
        Header: "Assigned To",
        accessor: "assigned_to",
        filterable: true,
        Cell: cellProps => {
          const { document_id, assigned_to, usage_status } = cellProps.row.original
          return (
            <UpdateAssignStatus assignedTo={assigned_to} docId={document_id} usage_status={usage_status} />
          )
        },
      },
      {
        Header: "Assign info",
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Button
                  type="button"
                   className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    if (rowData.assigned_to) {
                      setAssignData(rowData)
                      toggleAssignModel()
                      console.log(rowData.document_id)
                      dispatchaAssignDetails(rowData.document_id)
                    }
                  }}
                  style={{
                    backgroundColor: !rowData.assigned_to ? "gray" : "",
                  }}
                  disabled={!rowData.assigned_to}
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltipAssign" />
                  <UncontrolledTooltip
                    placement="top"
                    target="viewtooltipAssign"
                  >
                    View Assign Details
                  </UncontrolledTooltip>
                </Button>
              </li>
            </div>
          )
        },
      },
      {
        Header: "Usage",
        accessor: "usage_status",
        filterable: true,
        Cell: cellProps => {
          return (
            <>
              <Badge
                className={`p-2
                             ${cellProps.value ? "bg-success" : "bg-danger"}`}
              >
                {cellProps.value ? "used" : "Not used"}
              </Badge>
            </>
          )
        },
      },
      {
        Header: "Inv. Serial",
        accessor: "invoice_serial",
        filterable: true,
        Cell: cellProps => {
          return (
            <Button
              // to={`?invoice_id=${S_no}`}
              
              onClick={() => {toggleShowSpecificInvoice(); setInvoiceDetails(cellProps.row.original.invoice_id)}}
              className="badge badge-soft-primary font-size-11 m-1 border-0"
            >
              {cellProps.value || ""}
            </Button>
          )
        },
      },
      {
        Header: "Price",
        accessor: "price",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Usage info",
        Cell: cellProps => {
          const rowData = cellProps.row.original
          return (
            <div className="list-unstyled hstack gap-1 mb-0">
              <li>
                <Button
                  type="button"
                  className="btn btn-sm btn-soft-primary"
                  onClick={() => {
                    if (rowData.usage_status === "used") {
                      setUsageData(rowData)
                      toggleUsageModel()
                    }
                  }}
                  // onClick={() => {
                  //   setUsageData(rowData)
                  //   toggleUsageModel()
                  // }}
                  style={{
                    backgroundColor: !rowData.usage_status ? "gray" : "",
                  }}
                  disabled={!rowData.usage_status}
                >
                  <i className="mdi mdi-eye-outline" id="viewtooltip" />
                  <UncontrolledTooltip placement="top" target="viewtooltip">
                    View Usage Details
                  </UncontrolledTooltip>
                </Button>
              </li>
            </div>
          )
        },
      },
    ],
    []
  )

  return (
    <>
      {getDocumentsLoading ? (
        <div className="text-center m-auto pt-4">
          <Spinner className="ms-2" color="primary" />
        </div>
      ) : (
        <Row>
          <Col lg="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={(docList.length && docList) || []}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  customPageSize={5}
                  allListSize={(docList.length && docList) || []}
                  handleUserClick={toggle}
                  newItemBtnTxt="Add Document"
                  isAddUserList={true}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
      <AssignDetails
        assignData={assignData}
        modal={assignModel}
        toggle={toggleAssignModel}
      />
      <UsageDetails
        usageData={usageData}
        modal={usageModel}
        toggle={toggleUsageModel}
      />

      <InvoiceModal
        modal={showSpecificInvoice}
        toggle={toggleShowSpecificInvoice}
        invoiceDetails={invoiceDetails}
      />
    </>
  )
}

export default DocumentsList
