import {
  GET_DOCUMENTS_LIST,
  GET_DOCUMENTS_LIST_SUCCESS,
  GET_DOCUMENTS_LIST_FAIL,
  GET_USERS_LIST,
  GET_USERS_LIST_SUCCESS,
  GET_USERS_LIST_FAIL,
  POST_DOCUMENT_ASSIGNTO,
  POST_DOCUMENT_ASSIGNTO_SUCCESS,
  POST_DOCUMENT_ASSIGNTO_FAIL,
  GET_DOCUMENT_ASSIGN_DETAILS,
  GET_DOCUMENT_ASSIGN_DETAILS_SUCCESS,
  GET_DOCUMENT_ASSIGN_DETAILS_FAIL,
  POST_NEW_DOCUMENT,
  POST_NEW_DOCUMENT_SUCCESS,
  POST_NEW_DOCUMENT_FAIL,
  POST_NEW_DOCUMENT_RESET,
  POST_DOCUMENT_ASSIGNTO_RESET,
  GET_DOC_USAGE,
  GET_DOC_USAGE_SUCCESS,
  GET_DOC_USAGE_FAIL,
  GET_UNUSED_DOC,
  GET_UNUSED_DOC_SUCCESS,
  GET_UNUSED_DOC_FAIL
} from "./actionTypes"

const INIT_STATE = {
  documentsList: [],
  error: {},
  getDocumentsLoading: false,

  usersList: [],
  userError: {},
  getUsersLoading: false,

  editDocAssignTo: "",
  editDocAssignToError: {},
  editDocAssignToLoading: false,

  documentAssignDetails: "",
  documentAssignDetailsError: {},
  documentAssignDetailsLoading: false,

  addNewDocumentres: "",
  addNewDocumentError: null,
  addNewDocumentLoading: false,

  docUsageData: "",
  docUsageLoading: false,
  docUsageError: "",

  unusedDocData: "",
  unusedDocLoading: false,
  unusedDocError: "",
}

const DocumentsList = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_LIST:
      return {
        ...state,
        getDocumentsLoading: true,
      }
    case GET_DOCUMENTS_LIST_SUCCESS:
      return {
        ...state,
        documentsList: action.payload,
        getDocumentsLoading: false,
      }
    case GET_DOCUMENTS_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        getDocumentsLoading: false,
      }

    case GET_USERS_LIST:
      return {
        ...state,
        getUsersLoading: true,
      }
    case GET_USERS_LIST_SUCCESS:
      return {
        ...state,
        usersList: action.payload,
        getUsersLoading: false,
      }
    case GET_USERS_LIST_FAIL:
      return {
        ...state,
        userError: action.payload,
        getUsersLoading: false,
      }

    case POST_DOCUMENT_ASSIGNTO:
      return {
        ...state,
        editDocAssignToLoading: true,
      }
    case POST_DOCUMENT_ASSIGNTO_SUCCESS:
      return {
        ...state,
        editDocAssignTo: action.payload,
        editDocAssignToLoading: false,
      }
    case POST_DOCUMENT_ASSIGNTO_FAIL:
      return {
        ...state,
        editDocAssignToError: action.payload,
        editDocAssignToLoading: false,
      }

    case POST_DOCUMENT_ASSIGNTO_RESET:
      return {
        ...state,
        editDocAssignTo: "",
        editDocAssignToError: {},
        editDocAssignToLoading: false,
      }

    case GET_DOCUMENT_ASSIGN_DETAILS:
      return {
        ...state,
        documentAssignDetailsLoading: true,
        documentAssignDetails: {},
        documentAssignDetailsError: {},
      }
    case GET_DOCUMENT_ASSIGN_DETAILS_SUCCESS:
      return {
        ...state,
        documentAssignDetails: action.payload,
        documentAssignDetailsError: {},
        documentAssignDetailsLoading: false,
      }
    case GET_DOCUMENT_ASSIGN_DETAILS_FAIL:
      return {
        ...state,
        documentAssignDetails: "",
        documentAssignDetailsError: action.payload,
        documentAssignDetailsLoading: false,
      }

    case POST_NEW_DOCUMENT:
      return {
        ...state,
        addNewDocumentLoading: true,
        addNewDocumentres: "",
        addNewDocumentError: null,
      }

    case POST_NEW_DOCUMENT_SUCCESS:
      return {
        ...state,
        addNewDocumentres: action.payload,
        addNewDocumentLoading: false,
      }
    case POST_NEW_DOCUMENT_FAIL:
      return {
        ...state,
        addNewDocumentError: action.payload,
        addNewDocumentLoading: false,
      }
    case POST_NEW_DOCUMENT_RESET:
      return {
        ...state,
        addNewDocumentres: "",
        addNewDocumentError: null,
      }
    case GET_DOC_USAGE:
      return {
        ...state,
        docUsageLoading: true,
      }
    case GET_DOC_USAGE_SUCCESS:
      return {
        ...state,
        docUsageData: action.payload,
        docUsageLoading: false,
      }
    case GET_DOC_USAGE_FAIL:
      return {
        ...state,
        docUsageError: action.payload,
        docUsageLoading: false,
      }
      case GET_UNUSED_DOC:
        return {
          ...state,
          unusedDocLoading: true,
          unusedDocData: "",
          unusedDocError: "",
        }
      case GET_UNUSED_DOC_SUCCESS:
        return {
        ...state,
        unusedDocData: action.payload,
        unusedDocLoading: false,
      }
      case GET_UNUSED_DOC_FAIL:
        return {
        ...state,
        unusedDocError: action.payload,
        unusedDocLoading: false,
      }
    default:
      return state
  }
}

export default DocumentsList
