import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { useNavigate } from 'react-router-dom';

//Import Images
// import error from "../../assets/images/error-img.png";
import error from "../../assets/images/notauthorized-img.png";

const Pages401 = () => {
  //meta title
  document.title = "401 Not authorized | Buffalo Dashboard";
  const navigate = useNavigate();

  return (
    <React.Fragment>
      <div className="my-5 pt-5">
        <Container>
          <Row className="justify-content-center">
            <Col md="8" xl="6">
              <div className="my-3">
                <img src={error} alt="" className="img-fluid w-75" />
              </div>
              <div className="mt-5 text-center">
                  <button
                    className="btn btn-primary waves-effect waves-light "
                    // to="/dashboard"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </button>
                </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pages401;
