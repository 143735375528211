import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import {
  GET_INVOICES,
  GET_INVOICE_DETAIL,
  GET_INVOICES_STATUS,
  GET_TICKET_STATUS,
  POST_TICKET_STATUS,
  POST_INVOICES_STATUS,
  GET_INVOICE_PAYMENT_SUMMARY,
  POST_INVOICE,
} from "./actionTypes"
import {
  getInvoicesSuccess,
  getInvoicesFail,
  getInvoiceDetailSuccess,
  getInvoiceDetailFail,
  getInvoicesStatusSuccess,
  getInvoicesStatusFail,
  getTicketStatusSuccess,
  getTicketStatusFail,
  postTicketStatusSuccess,
  postTicketStatusFail,
  postInvoicesStatusSuccess,
  postInvoicesStatusFail,
  getInvoicePaymentSummarySuccess,
  getInvoicePaymentSummaryFail,
  postInvoiceSuccess,
  postInvoiceFail,
} from "./actions"
import {
  invoicesListDummyData,
  invoicesStatus,
  ticketStatus,
  invoiceDummyData,
  invoicePaymentSummary,
} from "pages/data"

//Include Both Helper File with needed methods
// import { getInvoices, getInvoiceDetail } from "helpers/fakebackend_helper"
import {
  postInvoice,
  getInvoices,
  getInvoicePayDetails,
  getInvoiceDetails,
} from "helpers/backend_helper"

function* fetchInvoices() {
  try {
    const response = yield call(getInvoices)
    //const response = invoicesListDummyData
    console.log(response)
    yield put(getInvoicesSuccess(response.data))
  } catch (error) {
    yield put(getInvoicesFail(error))
  }
}
// to fetch invoice details list data by invoice id
function* fetchInvoiceDetail({ invoiceId }) {
  try {
    console.log(invoiceId)
    const response = yield call(getInvoiceDetails, invoiceId)
    console.log(response)
    if (response.data) {
      yield put(getInvoiceDetailSuccess(response.data))
    }
  } catch (error) {
    yield put(getInvoiceDetailFail(error))
  }
}

function* fetchInvoicesStatus() {
  try {
    const response = yield invoicesStatus
    yield put(getInvoicesStatusSuccess(response))
  } catch (error) {
    yield put(getInvoicesStatusFail(error))
  }
}

function* postInvoiceStatusData({ payload: data }) {
  try {
    // const response = yield call(postInvoiceStatusData, data)
    yield put(postInvoicesStatusSuccess(data))
  } catch (error) {
    yield put(postInvoicesStatusFail(error))
  }
}

function* fetchTicketStatus() {
  try {
    const response = yield ticketStatus
    yield put(getTicketStatusSuccess(response))
  } catch (error) {
    yield put(getTicketStatusFail(error))
  }
}

function* postTicketStatusData({ payload: data }) {
  try {
    // const response = yield call(postInvoiceStatusData, data)
    yield put(postTicketStatusSuccess(data))
  } catch (error) {
    yield put(postTicketStatusFail(error))
  }
}

function* fetchInvoicePaymentSummary({ payload: data }) {
  try {
    console.log(data)
    const response = yield call(getInvoicePayDetails, data)
    yield put(getInvoicePaymentSummarySuccess(response.data))
  } catch (error) {
    yield put(getInvoicePaymentSummaryFail(error))
  }
}

function* postInvoiceData({ payload: data }) {
  try {
    const response = yield call(postInvoice, data)
    console.log(response)
    if (response.message) {
      yield put(postInvoiceSuccess(response))
    } else {
      yield put(postInvoiceFail(response))
    }
    console.log(response)
  } catch (error) {
    yield put(postInvoiceFail(error))
  }
}
function* invoiceSaga() {
  yield takeEvery(GET_INVOICES, fetchInvoices)
  yield takeEvery(GET_INVOICE_DETAIL, fetchInvoiceDetail)
  yield takeEvery(GET_INVOICES_STATUS, fetchInvoicesStatus)
  yield takeEvery(GET_TICKET_STATUS, fetchTicketStatus)
  yield takeEvery(POST_INVOICES_STATUS, postInvoiceStatusData)
  yield takeEvery(POST_TICKET_STATUS, postTicketStatusData)
  yield takeEvery(GET_INVOICE_PAYMENT_SUMMARY, fetchInvoicePaymentSummary)
  yield takeEvery(POST_INVOICE, postInvoiceData)
}

export default invoiceSaga
