import React, { useState, useEffect } from "react"

import * as Yup from "yup"
import { useFormik } from "formik"
import {
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
  FormFeedback,
  Label,
  Form,
} from "reactstrap"
import Select from "react-select"
import { useDispatch, useSelector } from "react-redux"
import { postNewDocument } from "store/actions"
import { set } from "lodash"

const CreatePaperModal = props => {
  const { modal, toggle, docList } = props

  const dispatch = useDispatch()
  const [usersList, setUsersList] = useState([])
  const [formRows, setFormRows] = useState([
    { id: 0, document_id: "", document_user: "", err: false, errExist: false },
  ])
  const [allFormValidate, setAllFormValidate] = useState(false)

  const { usersList: fetchUsersList, addNewDocumentLoading, addNewDocumentres, addNewDocumentError } = useSelector(
    state => state.DocumentsList
  )

  useEffect(() => {
    fetchUsersList && setUsersList(fetchUsersList)
  }, [fetchUsersList])

  const handleDocumetChange = (value, id) => {
    setAllFormValidate(false)
    const formRowCpy = [...formRows]
    formRowCpy.map(formRow => formRow.id === id && (formRow.errExist = false))
    formRowCpy.map(
      formRow => formRow.id === id && (formRow.document_id = value)
    )
    setFormRows(formRowCpy)
  }

  const onDocBlur = (id, value) => {
    const formRowCpy = [...formRows]
    formRowCpy.map(
      formRow =>
        formRow.id === id &&
        (value === "" ? (formRow.err = true) : (formRow.err = false))
    )
    // Check if it is exist before
    docList.map(
      doc =>
        doc.document_id === value &&
        formRowCpy.map(
          formRow => formRow.id === id && (formRow.errExist = true)
        )
    )

    // Check if user entered before
    formRowCpy.map(
      formRow =>
        (formRow.document_id === value && formRow.id !== id) && (formRow.errExist = true)
    )
    setFormRows(formRowCpy)
    // setFormRows(formRowCpy)
  }

  const onUserChange = (value, id) => {
    const formRowCpy = [...formRows]
    formRowCpy.map(
      formRow => formRow.id === id && (formRow.document_user = value)
    )
    setFormRows(formRowCpy)
  }

  const onDeleteFormRow = id => {
    const formRowCpy = [...formRows]
    const filtered = formRowCpy.filter(formRow => formRow.id !== id)
    setFormRows(filtered)
  }

  const onAddFormRow = () => {
    const formRowCpy = [...formRows]
    formRowCpy.push({
      id: formRowCpy[formRowCpy.length - 1].id + 1,
      document_id: "",
      document_user: "",
      err: false,
    })
    setFormRows(formRowCpy)
  }

  const submitAllForm = () => {
    const formRowCpy = [...formRows]
    let err = false

    formRowCpy.map(
      formRow => formRow.document_id === "" && (formRow.err = true)
    )
    formRowCpy.map(
      formRow =>
        (formRow.document_id === "" || formRow.errExist) && (err = true)
    )

    setFormRows(formRowCpy)

    // (!allFormValidate && allFormValidate !== null) && dispatch(postNewDocument(formRows))
    if (!err) {
      setAllFormValidate(false)
      const formRowCpy = formRows.map(row => ({ ...row }));
      formRowCpy.map(row => {delete row.id; delete row.errExist; delete row.err;})
      dispatch(postNewDocument({ documents: formRowCpy }))
    } else {
      setAllFormValidate(true)
    }
    // toggle()
  }

  const resetForm = () => {
    setFormRows([
      {
        id: 0,
        document_id: "",
        document_user: "",
        err: false,
        errExist: false,
      },
    ])
    setAllFormValidate(false)
    toggle()
  }
  useEffect(() => {
    if (!addNewDocumentLoading) {
        (addNewDocumentError !== null || addNewDocumentres !== "") && resetForm()
    }
  }, [addNewDocumentLoading])

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        Add Paper Id
      </ModalHeader>
      <ModalBody>
        <Form>
          <Row className="gap-2">
            {(formRows || []).map((formRow, key) => (
              <Row key={key} className="align-items-start">
                <Col className="mb-3">
                  <Label
                    className={`form-label ${key === 0 ? "d-block" : "d-none"}`}
                  >
                    Document Id
                  </Label>
                  <Input
                    name={`paperDocId${formRow.id}`}
                    type="text"
                    placeholder="Insert New Paper Document Id"
                    onChange={e => {
                      handleDocumetChange(e.target.value, formRow.id)
                    }}
                    onBlur={e => onDocBlur(formRow.id, e.target.value)}
                    value={formRow.document_id}
                    className={
                      formRow.err || formRow.errExist ? "is-invalid" : ""
                    }
                  />
                  {formRow.errExist && (
                    <span className="text-danger">This Id is exist.</span>
                  )}
                </Col>

                <Col className="mb-3">
                  <Label
                    className={`form-label ${key === 0 ? "d-block" : "d-none"}`}
                  >
                    User
                  </Label>
                  <Select
                    value={usersList.find(function (option) {
                      return option.id === formRow.document_user
                    })}
                    onChange={e => onUserChange(e.id, formRow.id)}
                    options={usersList}
                    getOptionLabel={list => list["first_name"]}
                    getOptionValue={list => list["id"]}
                    className="select2-selection"
                    type="select"
                  />
                </Col>

                <Col xs="auto" className="align-self-center">
                  <div className="d-grid">
                    <button
                      type="button"
                      className={`btn btn-danger py-1 ${
                        key === 0 ? "mt-2" : "mb-3"
                      } `}
                      onClick={() => onDeleteFormRow(formRow.id)}
                      disabled={formRows.length === 1}
                    >
                      <i className="mdi mdi-delete font-size-16"></i>
                    </button>
                  </div>
                </Col>
              </Row>
            ))}
          </Row>
          <Row>
            <Col xs="auto">
              <button
                className="btn btn-success btn-label mx-1"
                onClick={e => {
                  e.preventDefault()
                  onAddFormRow()
                }}
              >
                <i className="bx bx-plus label-icon"></i> Add New Paper
              </button>
            </Col>
          </Row>
          {allFormValidate && (
            <Row>
              <Col xs={12} className="text-center">
                <span style={{ color: "#cd0000" }}>
                  Please fill all required fields.
                </span>
              </Col>
            </Row>
          )}
          <Row>
            <Col>
              <div className="d-flex gap-2 mt-5">
                <button
                  type="button"
                  onClick={() => submitAllForm()}
                  className="btn btn-primary btn-label mx-1 mb-2"
                >
                  {addNewDocumentLoading ? (
                    <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                  ) : (
                    <i className="bx bx-check label-icon"></i>
                  )}
                  Submit
                </button>
                <button
                  type="button"
                  onClick={() => {
                    resetForm()
                  }}
                  className="btn btn-outline-primary btn-label mx-1 mb-2"
                >
                  <i className="bx bx-x label-icon"></i>
                  Cancel
                </button>
              </div>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  )
}

export default CreatePaperModal
