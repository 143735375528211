import TableContainer from "components/Common/TableContainer"
import React, { useEffect, useMemo, useState } from "react"
import {
  Badge,
  Card,
  CardBody,
  Col,
  Modal,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap"
import { convertDate } from "pages/add-paper/utils/convertDate"

import { usageListDummyData } from "pages/data"

import { useSelector } from "react-redux"

const AssignDetails = props => {
  const { modal, toggle, assignData } = props

  const { documentAssignDetails, documentAssignDetailsLoading } = useSelector(
    state => state.DocumentsList
  )
  const [assignDetails, setAssignDetails] = useState("")

  useEffect(() => {
    if (documentAssignDetails) {
      console.log(documentAssignDetails)
      setAssignDetails(documentAssignDetails)
    }
  }, [documentAssignDetails])

 
  const columns = useMemo(
    () => [
      {
        Header: "Assigned By",
        accessor: "assignedBy",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      {
        Header: "Assigned To",
        accessor: "assignedTo",
        filterable: true,
        Cell: cellProps => {
          return <>{cellProps.value || ""}</>
        },
      },
      // {
      //   Header: "Assigned Date",
      //   accessor: "assignedDate",
      //   filterable: true,
      //   Cell: cellProps => {
      //     return <>{cellProps.value ? convertDate(cellProps.value) : ""}</>
      //   },
      // },
    ],
    []
  )

  return (
    <Modal isOpen={modal} toggle={toggle} size="xl">
      <ModalHeader toggle={toggle} tag="h4">
        {`Assign Details for document id `} <span className="text-primary">{`${assignDetails !== "" ? (assignDetails.document_id ? assignDetails.document_id : "") : ""}`}</span>
      </ModalHeader>
      {documentAssignDetailsLoading ? (
        <div className="text-center m-auto pt-4">
          <Spinner className="ms-2" color="primary" />
        </div>
      ) : (
        <Row>
          <Col lg="12" className="mt-2 mx-3">
            <Row>
              <Col lg="6">
                <div className="flex justify-content-between mb-3">
                  <span style={{ fontWeight: "600" }}>
                    Current Assign Status:{" "}
                  </span>
                  <span style={{ fontWeight: "500" }}>
                    <Badge
                      className={`p-2 
                             ${
                               assignDetails.assigned_status ? "bg-success" : "bg-danger"
                             }`}
                    >
                      {assignDetails.assigned_status ? "Assigned" : "Not Assigned"}
                    </Badge>
                  </span>
                </div>
                <div className="flex justify-content-between mb-3">
                  <span style={{ fontWeight: "600" }}>
                    Last Assigned Date:{" "}
                  </span>
                  <span style={{ fontWeight: "500" }}>
                    {convertDate(assignDetails.updated_at)}
                  </span>
                </div>
              </Col>
              <Col lg="6">
                <div className="flex justify-content-between mb-3">
                  <span style={{ fontWeight: "600" }}>Last Assigned By: </span>
                  <span style={{ fontWeight: "500" }}>
                    {(assignDetails !== "") ? assignDetails.assignments && assignDetails.assignments[assignDetails.assignments.length - 1].assignedBy : ""}
                  </span>
                </div>
                <div className="flex justify-content-between mb-3">
                  <span style={{ fontWeight: "600" }}>Last Assigned To: </span>
                  <span style={{ fontWeight: "500" }}>
                  {(assignDetails !== "") ? assignDetails.assignments && assignDetails.assignments[assignDetails.assignments.length - 1].assignedTo : ""}

                  </span>
                </div>
              </Col>
              {assignData.usage_status !== null && (
                <Col lg="6">
                  <div className="flex justify-content-between mb-3">
                    <span style={{ fontWeight: "600" }}>usedBy: </span>
                    <span style={{ fontWeight: "500" }}>
                      {assignDetails.usedBy}
                    </span>
                  </div>
                </Col>
              )}
            </Row>
          </Col>
          <Col lg="12">
            <Card>
              <CardBody>
                <TableContainer
                  columns={columns}
                  data={assignDetails.assignments || []}
                  isGlobalFilter={true}
                  isAddOptions={false}
                  customPageSize={5}
                  allListSize={assignDetails !== "" ? (assignDetails.assignments ? assignDetails.assignments.length : 0) : 0}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      )}
    </Modal>
  )
}

export default AssignDetails
