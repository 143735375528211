import React, { useState, createContext } from "react"

let initialNotes = "";

export const NotesContext = createContext({
    notes: initialNotes,
    updateNotesHandler: notes => {},
  })

  const NotesContextProvider = props => {
    const [notes, setNotes] = useState(initialNotes)
    const [notesRest, setNotesReset] = useState(false)
  
    const updateNotesHandler = notes => {
      setNotes(notes)
    }
    const notesResetHandler = (state) => {
        setNotesReset(state)
    }
  
    return (
      <NotesContext.Provider
        value={{
          notes: notes,
          updateNotesHandler: updateNotesHandler,
          notesRest: notesRest,
          notesResetHandler: notesResetHandler
        }}
      >
        {props.children}
      </NotesContext.Provider>
    )
  }
  export default NotesContextProvider