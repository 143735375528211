import React, { useRef, useContext, useEffect, useState } from "react"
import { Col, Container, Row } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import invoiceImageWithWaterMark from "./invoice-image-water-mark.jpg"
import HeaderInputs from "pages/add-invoice/headerInputs"
import classes from "./index.module.scss"
import SingleTabelRow from "pages/add-invoice/tabel/singleTabelRow"
import TabelFooter from "pages/add-invoice/tabel/tabelFooter"
import PrintButton from "pages/add-invoice/printButton"
import PaymentSummary from "pages/add-invoice/paymentSummary/PaymentSummary"
import NonPrintableContainer from "pages/add-invoice/notInPrint/nonPrintableContainer"

import PaymentSummaryContextProvider from "pages/add-invoice/context/paymentSummaryContext"
import TabelContextProvider, {
  TabelContext,
} from "pages/add-invoice/context/tabelContext"
import DocumentIdContextProvider from "pages/add-invoice/context/documentIdContext"
import NotesContextProvider from "./context/notesContext"

import Submit from "pages/add-invoice/printInvoice/submit"
import PetContextProvider from "./context/petContext"
import Notes from "./Notes"

const Invoice = () => {
  document.title = "Dragon | add invoice"
  const [errList, setErrList] = useState([])
  const [rows, setRows] = useState(Array.from({ length: 12 }, (_, index) => index + 1));
  const printContentRef = useRef()

  const tabelCtx = useContext(TabelContext)
  const { invoiceData, totalData, dataTableReset } = tabelCtx

  useEffect(() => {
    const handleKeyDown = event => {
      if (event.ctrlKey && event.key === "p") {
        event.preventDefault()
      }
    }

    document.addEventListener("keydown", handleKeyDown)

    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])
  const handleErr = errList => {
    setErrList(errList)
  }

  const resetRows = () => {
    setRows(Array.from({ length: 12 }, (_, index) => index + 1));
  };

  useEffect(() => {
    dataTableReset && resetRows();
  }, [dataTableReset])

  return (
    <div className="page-content">
      <Container fluid>
        <Breadcrumbs title="Invoices" breadcrumbItem="Add Invoice" />
        {/* <PrintButton printContentRef={printContentRef.current} /> */}
        <Row>
          <Col md={9} sm={12}>
            {errList.length > 0 && (
              <div className="text-danger fw-bold">
                <span>Please fill the following fields: </span>
                {errList.map((one, index) => (
                  <span key={index}>
                    {one} {index === errList.length - 1 ? "" : ", "}
                  </span>
                ))}
                .
              </div>
            )}
          </Col>
          <Col md={3} sm={12}>
            <Submit
              printContentRef={printContentRef.current}
              handleErr={handleErr}
            />
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ overflowX: "scroll" }}>
        <div
          className={classes.invoice_main_page_container}
          style={{ width: "1200px", position: "relative" }}
        >
          <img
            className={classes.invoice_main_page_image}
            src={invoiceImageWithWaterMark}
          />
          <div
            ref={printContentRef}
            className={classes.invoice_main_page_inputs}
          >
            <HeaderInputs />
            <div style={{ marginTop: "50px" }}>
              {rows.map(
                (one, index) => (
                  <SingleTabelRow key={one} index={index} rowNumber={one} />
                )
              )}
            </div>
            <TabelFooter />

            <>
              <PaymentSummary
                totalLineTotal={totalData.lineTotal}
                topTabelRowsData={invoiceData.tabelData.filter(
                  one => one.itemCode && one.totalPrice && one.lineTotal
                )}
              />
              <Notes />
            </>
          </div>
          <NonPrintableContainer />
        </div>
      </Container>
    </div>
  )
}

const InvoicePage = () => {
  return (
    <TabelContextProvider>
      <PaymentSummaryContextProvider>
        <DocumentIdContextProvider>
          <PetContextProvider>
            <NotesContextProvider>
              <Invoice />
            </NotesContextProvider>
          </PetContextProvider>
        </DocumentIdContextProvider>
      </PaymentSummaryContextProvider>
    </TabelContextProvider>
  )
}

export default InvoicePage
