import { post as postAuth , get as getAuth } from "./axios_auth_api"
import { post as postNonAuth , get as getNonAuth  } from "./axios_api"

//////// Buffalo Login ////////////
export const postLogin = async (data) => {
    try {
      return await postNonAuth("api/login", data).then((response) => response)
    } catch({response}){
      return response;
    }
  }
  export const postRefreshToken = async () => {
    try {
      return await postAuth("api/refresh-token").then((response) => response)
    } catch({response}){
      return response;
    }
  }

export const getItems = async () => await getAuth('TblItem/getPage');

export const getItemNotes = async (itemId) => await getAuth(`TblItemNote/getPage?userQuery=fkItemId=${itemId}`);

export const getInvoices = async () => await getAuth('api/invoices');
export const postInvoice = async (data) => await postAuth('api/invoice', data);
export const getInvoicePayDetails = async (invoiceId) => await getAuth(`api/invoices/${invoiceId}/payments`);
export const getInvoiceDetails = async (invoiceId) => await getAuth(`api/invoices/${invoiceId}`);

// Documents
export const addDocuments = async (data) => await postAuth('api/documents', data);
export const getDocuments = async () => await getAuth('api/documents');
export const postUserToDoc = async (data) => await postAuth('api/documents/assign-to-user', data);
export const getDocUsageDetails = async (docId) => await getAuth(`api/documents/${docId}/usage-details`);
export const getDocAssignDetails = async (docId) => await getAuth(`api/documents/${docId}/assignment-details`);
export const getUnusedDocument = async () => await getAuth('api/get_unused_documents');

// Get users
export const getUsers = async () => await getAuth('api/users');


