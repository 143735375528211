import React, { useContext, useEffect, useState } from "react"
import classes from "../index.module.scss"
import SingleTabelRow from "pages/add-invoice/paymentSummary/singleTabelRow"
import { Input } from "reactstrap"

import { PaymentSummaryContext } from "pages/add-invoice/context/paymentSummaryContext"
import SinglePetTableRow from "./SinglePetTableRow"
import { PetContext } from "pages/add-invoice/context/petContext"

const PaymentSummary = props => {
  const { totalLineTotal, topTabelRowsData } = props
  const [rows, setRows] = useState(Array.from({ length: 7 }, (_, index) => index + 1));
  const [petRows, setPetRows] = useState(Array.from({ length: 7 }, (_, index) => index + 1));
  const paymentSummaryCtx = useContext(PaymentSummaryContext)
  const { totalAmout, paymentReset } = paymentSummaryCtx

  const petContext = useContext(PetContext)
  const {
    petReset,
  } = petContext

  const resetPetRows = () => {
    setPetRows(Array.from({ length: 7 }, (_, index) => index + 1));
  };

  useEffect(() => {
    petReset && resetPetRows();
  }, [petReset])

  const resetPaymentRows = () => {
    setRows(Array.from({ length: 7 }, (_, index) => index + 1));
  };

  useEffect(() => {
    paymentReset && resetPaymentRows();
  }, [paymentReset])

  return (
    <div className={`${classes.paymentSummary} d-flex`}>
      <div>
        {petRows.map(
          (one, index) => (
            <SinglePetTableRow
              key={one}
              index={index}
              rowNumber={one}
              topTabelRowsData={topTabelRowsData}
            />
          )
        )}
      </div>
      <div>
        {rows.map(
          (one, index) => (
            <SingleTabelRow
              key={one}
              index={index}
              rowNumber={one}
              topTabelRowsData={topTabelRowsData}
            />
          )
        )}
      <div style={{ display: "flex", paddingTop: "15px" }}>
        <div style={{ marginLeft: "150px" }}>
          <Input
            type="number"
            value={totalAmout === 0 ? "" : totalAmout}
            className={classes.transparent_input}
            readOnly
          />
        </div>
        <div style={{ marginLeft: "220px" }}>
          <Input
            type="number"
            value={
              (!totalLineTotal || !totalAmout) &&
              totalLineTotal - totalAmout === 0
                ? ""
                : totalLineTotal - totalAmout
            }
            className={classes.transparent_input}
            readOnly
          />
        </div>
      </div>
      </div>

    </div>
  )
}

export default PaymentSummary
