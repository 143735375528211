import React, { useContext, useState, useEffect } from "react"
import ReactToPrint from "react-to-print"

import * as Yup from "yup"
import { useFormik } from "formik"

import {
  Badge,
  Button,
  Col,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import Select from "react-select"

import { DocumentIdContext } from "pages/add-invoice/context/documentIdContext"
import { PetContext } from "pages/add-invoice/context/petContext"
import { TabelContext } from "pages/add-invoice/context/tabelContext"
import { PaymentSummaryContext } from "pages/add-invoice/context/paymentSummaryContext"
import { NotesContext } from "../context/notesContext"
import {
  getDocumentsList,
  postInvoice,
  postInvoiceReset,
  postNewDocument,
  postNewDocumentReset,
  getUnusedDoc
} from "store/actions"
import { useDispatch, useSelector } from "react-redux"
import { toast } from "react-toastify"

let errMessage = "Please Enter New Paper Document Id"
const PrintModel = props => {
  const { isOpen, toggle, printContentRef, close } = props
  const [showDocId, setShowDocId] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  // btnaddSubmit to be sure document added from here not from outside
  const [btnAddSubmit, setBtnAddSubmit] = useState(false)
  const [documentsListData, setDocumentsListData] = useState([])
  const [selectedDoc, setSelectedDoc] = useState("")

  const DocumentIdCtx = useContext(DocumentIdContext)
  const { paperId, papersIds, updatePaperIdHandler, paperIdResetHandler } =
    DocumentIdCtx

  const { tabelData, petResetHandler } = useContext(PetContext)
  const { filledTabelData, paymentResetHandler } = useContext(
    PaymentSummaryContext
  )
  const { invoiceData, headerResetHandler, dataTableResetHandler } =
    useContext(TabelContext)
  const { notes, notesResetHandler } = useContext(NotesContext)

  const dispatch = useDispatch()
  const { postInvoiceRes, postInvoiceLoading, postInvoiceError } = useSelector(
    state => state.Invoices
  )
  const {
    documentsList,
    addNewDocumentLoading,
    addNewDocumentres,
    addNewDocumentError,
    unusedDocData,
    unusedDocLoading,
    unusedDocError
  } = useSelector(state => state.DocumentsList)
  const deocs = [{"id":24,"document_id":"x0-01"}]

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      paperDocId: "",
    },
    validationSchema: Yup.object({
      paperDocId: Yup.string().required(errMessage),
    }),
    onSubmit: values => {
      // TODO: submit values
      setBtnAddSubmit(true)
      const data = [{ document_id: values.paperDocId, document_user: "" }]
      dispatch(postNewDocument({ documents: data }))
    },
  })

  // useEffect(() => {
  //   console.log(documentsList)
  //   if (documentsList) {
  //     documentsList.data &&
  //       documentsList.data.length &&
  //       setDocumentsListData(documentsList.data)
  //   }
  // }, [documentsList])

  useEffect(() => {
    (unusedDocData !== "" && unusedDocData.length) && setDocumentsListData(unusedDocData)
  }, [unusedDocData])

  // Fetch Document List after adding new document and set value to new document
  useEffect(() => {
    if (documentsListData.length > 0 && btnAddSubmit) {
      updatePaperIdHandler(validation.values.paperDocId)
      setBtnAddSubmit(false)
      validation.resetForm()
      // to prevent open add new document modal after response have value
      dispatch(postNewDocumentReset())
    }
  }, [documentsListData])

  useEffect(() => {
    if (addNewDocumentres !== "" && btnAddSubmit) {
      dispatch(getUnusedDoc())
      // setBtnAddSubmit(false)
    } else if (addNewDocumentError !== null && btnAddSubmit) {
      setBtnAddSubmit(false)
      toast.dismiss()
      toast.error(addNewDocumentError.message)
    }
  }, [addNewDocumentres, addNewDocumentError])

  useEffect(() => {
    if (paperId) {
      validation.setFieldError("paperDocId", "")
      setShowErrorMessage(false)
    }
  }, [paperId])

  useEffect(() => {
    if (showErrorMessage) {
      validation.setFieldError("paperDocId", errMessage)
    }
  }, [showErrorMessage])
  const handleAllData = async () => {
    invoiceData.headerData.manual_invoice_no = paperId
    const allInvoiceData = {
      headerData: invoiceData.headerData,
      tableData: invoiceData.tabelData,
      memberShip: invoiceData.memberShip,
      totalData: invoiceData.totalDataValue,
      petDataList: tabelData,
      paymentSummaryList: filledTabelData,
      notes: notes,
      // paperDocId: paperId,
    }
    console.log(allInvoiceData)
    dispatch(postInvoice(allInvoiceData))
  }

  useEffect(() => {
    if (postInvoiceRes !== "") {
      toggle()
      dispatch(postInvoiceReset())

      // Reset All data will move after response is success
      paperIdResetHandler()
      notesResetHandler(true)
      headerResetHandler(true)
      dataTableResetHandler(true)
      petResetHandler(true)
      paymentResetHandler(true)
    }
  }, [postInvoiceRes])

  useEffect(() => {
    isOpen && dispatch(getUnusedDoc())
  }, [isOpen])

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle} tag="h4">
        Print Invoice
      </ModalHeader>
      <ModalBody>
        <Row>
          <Label className="form-label">Paper Document Id</Label>
          <Select
            value={{
              value: paperId ? paperId : "Select...",
              label: paperId ? paperId : "Select...",
            }}
            onChange={selectedOption => {
              setSelectedDoc(selectedOption.value)
              updatePaperIdHandler(selectedOption.value)
            }}
            options={
              documentsListData.length
                ? documentsListData.map(one => ({
                    value: one.document_id,
                    label: one.document_id,
                  }))
                : []
            }
            className="select2-selection z-3"
          />
          {showErrorMessage ? (
            <span className="text-danger font-size-12 mx-2" type="invalid">
              {errMessage}
            </span>
          ) : null}
        </Row>
        <Row className="mt-4">
          <Col>
            <Badge
              color="primary"
              className="me-1 fw-bold font-size-12 p-1 px-3 "
              style={{ cursor: "pointer" }}
              onClick={() => setShowDocId(prevState => !prevState)}
            >
              The Paper Document id not listed here
            </Badge>
          </Col>
        </Row>
        {showDocId ? (
          <div className="mt-4">
            <Label className="form-label">New Paper Document Id</Label>
            <div className="input-group">
              <Input
                name="paperDocId"
                type="text"
                placeholder="Insert New Paper Document Id"
                onChange={validation.handleChange}
                onBlur={validation.handleBlur}
                value={validation.values.paperDocId || ""}
                invalid={
                  validation.touched.paperDocId && validation.errors.paperDocId
                    ? true
                    : false
                }
              />
              <button
                className="btn btn-primary"
                onClick={() => validation.handleSubmit()}
                disabled={addNewDocumentLoading}
              >
                {addNewDocumentLoading ? (
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                ) : (
                  <i className="bx bx-plus"></i>
                )}
              </button>
              {validation.touched.paperDocId && validation.errors.paperDocId ? (
                <FormFeedback type="invalid">
                  {validation.errors.paperDocId}
                </FormFeedback>
              ) : null}
            </div>
          </div>
        ) : (
          <></>
        )}
        <Row>
          <Col>
            <div className="d-flex gap-2 mt-5">
              {paperId ? (
                <ReactToPrint
                  trigger={() => (
                    <button
                      type="button"
                      className="btn btn-primary btn-label mx-1 mb-2"
                    >
                      <>
                        <i className="bx bxs-printer label-icon"></i> Print
                      </>
                    </button>
                  )}
                  content={() => printContentRef}
                  onBeforeGetContent={() => {
                    handleAllData() // This will be called before the print content is rendered
                  }}
                />
              ) : (
                <button
                  type="button"
                  className="btn btn-primary btn-label mx-1 mb-2"
                  onClick={() => setShowErrorMessage(true)}
                >
                  <>
                    <i className="bx bxs-printer label-icon"></i> Print
                  </>
                </button>
              )}
              <button
                onClick={close}
                type="button"
                className="btn btn-primary btn-label mx-1 mb-2"
              >
                <>
                  <i className="bx bx-x label-icon"></i> Cancel
                </>
              </button>
            </div>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  )
}

export default PrintModel
