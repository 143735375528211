/* INVOICES */
export const GET_INVOICES = "GET_INVOICES"
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS"
export const GET_INVOICES_FAIL = "GET_INVOICES_FAIL"

export const GET_INVOICE_DETAIL = "GET_INVOICE_DETAIL"
export const GET_INVOICE_DETAIL_SUCCESS = "GET_INVOICE_DETAIL_SUCCESS"
export const GET_INVOICE_DETAIL_FAIL = "GET_INVOICE_DETAIL_FAIL"

export const GET_INVOICES_STATUS = "GET_INVOICES_STATUS"
export const GET_INVOICES_STATUS_SUCCESS = "GET_INVOICES_STATUS_SUCCESS"
export const GET_INVOICES_STATUS_FAIL = "GET_INVOICES_STATUS_FAIL"

export const POST_INVOICES_STATUS = "POST_INVOICES_STATUS"
export const POST_INVOICES_STATUS_SUCCESS = "POST_INVOICES_STATUS_SUCCESS"
export const POST_INVOICES_STATUS_FAIL = "POST_INVOICES_STATUS_FAIL"

export const GET_TICKET_STATUS = "GET_TICKET_STATUS"
export const GET_TICKET_STATUS_SUCCESS = "GET_TICKET_STATUS_SUCCESS"
export const GET_TICKET_STATUS_FAIL = "GET_TICKET_STATUS_FAIL"

export const POST_TICKET_STATUS = "POST_TICKET_STATUS"
export const POST_TICKET_STATUS_SUCCESS = "POST_TICKET_STATUS_SUCCESS"
export const POST_TICKET_STATUS_FAIL = "POST_TICKET_STATUS_FAIL"

export const GET_INVOICE_PAYMENT_SUMMARY = "GET_INVOICE_PAYMENT_SUMMARY"
export const GET_INVOICE_PAYMENT_SUMMARY_SUCCESS = "GET_INVOICE_PAYMENT_SUMMARY_SUCCESS"
export const GET_INVOICE_PAYMENT_SUMMARY_FAIL = "GET_INVOICE_PAYMENT_SUMMARY_FAIL"

export const POST_INVOICE = "POST_INVOICE"
export const POST_INVOICE_SUCCESS = "POST_INVOICE_SUCCESS"
export const POST_INVOICE_FAIL = "POST_INVOICE_FAIL"
export const POST_INVOICE_RESET = "POST_INVOICE_RESET"
