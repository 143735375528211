import {
  LOGIN_USER_DATA,
  LOGIN_SUCCESS_DATA,
  LOGOUT_USER_CLEAR,
  LOGOUT_USER_SUCCESS_CLEAR,
  LOGIN_ERROR,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  REFRESH_TOKEN,
  REFRESH_TOKEN_SUCCESS,
  REFRESH_TOKEN_ERROR,
} from "./actionTypes"

const initialState = {
  error: "",
  loading: false,
  loginData: "",
}

const loginUser = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER_DATA:
      state = {
        ...state,
        loading: true,
        error: "",
      }
      break
    case LOGIN_SUCCESS_DATA:
      state = {
        ...state,
        loading: false,
        // isUserLogout: false,
        loginData: action.payload,
        error: "",
      }
      break
    case LOGOUT_USER_CLEAR:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS_CLEAR:
      state = { ...state, loginData: "" }
      break
    case LOGIN_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        loginData: "",
        // isUserLogout: false,
      }
      break
    case LOGOUT_USER:
      state = { ...state }
      break
    case LOGOUT_USER_SUCCESS:
      state = { ...state, loginData: "", loading: "" }
      break
    case REFRESH_TOKEN:
      state = {
        ...state,
        loading: true,
        error: "",
      }
      break
    case REFRESH_TOKEN_SUCCESS:
      state = {
        ...state,
        loading: false,
        // isUserLogout: false,
        loginData: action.payload,
        error: "",
      }
      break
    case REFRESH_TOKEN_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        loginData: "",
        // isUserLogout: false,
      }
    
    default:
      state = { ...state }
      break
  }
  return state
}

export default loginUser
