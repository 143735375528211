import React, { useContext, useEffect, useState } from "react"
import classes from "./index.module.scss"
import { NotesContext } from "./context/notesContext"
const Notes = () => {
    const notesCtx = useContext(NotesContext)
    const [notes, setNotes] = useState("")
    const { updateNotesHandler, notesRest, notesResetHandler } = notesCtx

    useEffect(() => {
      updateNotesHandler(notes)
    }, [notes])

    useEffect(() => {
      if (notesRest) {
        setNotes("")
        notesResetHandler(false)
      }
    }, [notesRest])

  return (
    <div>
      <textarea
        className={`${classes.transparent_input} ${classes.notes}`}
        placeholder=""
        rows={4}
        maxLength={180} // Example length, adjust based on the lines visible and font size.
        // onInput={e => handleInput(e)}
        value={notes}
        onChange={e => setNotes(e.target.value)}
      ></textarea>
    </div>
  )
}

export default Notes
