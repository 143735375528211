import React, { useEffect, useState } from "react"
import {
  Modal,
  ModalHeader,
  Row,
  Col,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { useDispatch, useSelector } from "react-redux"
import {
  postInvoicesStatus,
  postTicketStatus,
  postDocumentAssignTo,
  postDocumentAssignToReset
} from "store/actions"
import { toast } from "react-toastify"

const ConfirmationModal = props => {
  const { modal, toggle, confirmAction, cancelChange, s_no, newValue, close } =
    props

  const dispatch = useDispatch()

  const { postInvoiceStatusLoading, postInvoiceSatusRes, postTicketStatusRes } =
    useSelector(state => state.Invoices)
  const { editDocAssignTo, editDocAssignToLoading } = useSelector(
    state => state.DocumentsList
  )

  const [isSubmitDisable, setIsSubmitDisable] = useState(false)

  const handleCancel = () => {
    toggle()
    cancelChange()
  }

  const handleConfirm = () => {
    const data = {
      s_no,
      newValue,
    }
    if (confirmAction === "invoiceStatus") {
      dispatch(postInvoicesStatus(data))
    } else if (confirmAction === "ticketStatus") {
      dispatch(postTicketStatus(data))
    } else if (confirmAction === "docAssignStatus") {
      // const data3 = {
      //   userAssignTo: newValue,
      //   userUpdateAssign: 1,
      //   docId: S_no,
      // }
      const dataTosend = {
        document_id: s_no,
        user_id: newValue,
      }
      console.log(dataTosend)
      newValue !== "" && dispatch(postDocumentAssignTo(dataTosend))
    }
  }

  useEffect(() => {
    postInvoiceSatusRes !== "" && close()
    postTicketStatusRes !== "" && close()
    if (editDocAssignTo !== "") {
      if (editDocAssignTo.message) {
        toast.dismiss()
        toast.success(editDocAssignTo.message)
        close()
        dispatch(postDocumentAssignToReset())
      } else {
        toast.dismiss()
        toast.error(editDocAssignTo.message)
        close()
        dispatch(postDocumentAssignToReset())
        
      }
    }
  }, [postInvoiceSatusRes, postTicketStatusRes, editDocAssignTo])

  return (
    <Modal
      isOpen={modal}
      toggle={toggle}
      backdrop={"static"}
      id="staticBackdrop"
    >
      <ModalBody>
        <Row>
          <Col lg="12" className="text-center">
            <Row>
              <i
                className="mdi mdi-alert-circle-outline"
                style={{ fontSize: "9em", color: "orange" }}
              />
            </Row>
            <Row>
              <h5>{`Are you sure you want to ${
                confirmAction === "invoiceStatus"
                  ? "Update Invoice Status"
                  : "Update Ticket Status"
              }?`}</h5>
            </Row>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleConfirm}
            disabled={isSubmitDisable}
          >
            {isSubmitDisable && (
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
            )}
            Yes
          </button>
          <button
            type="button"
            className="btn btn-light"
            onClick={handleCancel}
          >
            Cancel
          </button>
        </div>
      </ModalFooter>
    </Modal>
  )
}

export default ConfirmationModal
