import { toast } from "react-toastify"
import axiosAuthInstance from "../axios/axiosAuthInstance"

// export async function get(url, config = {}) {
//     return await axiosApi
//       .get(url, { ...config })
//       .then((response) => response.data);
//   }

const unAuthorizedHandler = (err, url) => {
  console.log("err", err)
  toast.dismiss()
  toast.error(err.message || "Something went wrong")
  if (err.response.status === 401) {
    localStorage.removeItem("token")
    // window.location.href = "/login"
  }
}

export async function get(url, config = {}) {
  return await axiosAuthInstance
    .get(url, { ...config })
    .then(response => response.data)
    .catch(err => unAuthorizedHandler(err, url))
}

export async function post(url, data, config = {}) {
  return await axiosAuthInstance
    .post(url, data)
    .then(response => response.data)
    .catch(err => unAuthorizedHandler(err, url))
}
