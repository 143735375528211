import React, { useContext, useEffect } from "react"
import classes from "../index.module.scss"
import { Input } from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"

import { TabelContext } from "../context/tabelContext"

const TabelFooter = () => {
  const tabelCtx = useContext(TabelContext)
  const { totalData, dataTableResetHandler, dataTableReset, updateMembershipHandler } = tabelCtx

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Please Enter Sales Type"),
    }),
    onSubmit: values => {
      //
    },
  })

  const handleCheckboxChange = event => {
    const { name, checked } = event.target
    const salesType = checked ? name : ""
    validation.setFieldValue("type", salesType)
    updateMembershipHandler(salesType)
  }

  useEffect(() => {
    dataTableReset && validation.setFieldValue("type", "")
    dataTableReset && dataTableResetHandler(false)
  }, [dataTableReset])
  return (
    <div
      className={classes.footer_container}
      style={{ fontSize: "14px", fontWeight: "600" }}
    >
      <div style={{ marginLeft: "830px" }}>{totalData.price.toFixed(2)}</div>
      <div style={{ marginLeft: "965px", marginTop: "30px" }}>
        {totalData.discount.toFixed(2)}
      </div>
      <div style={{ marginLeft: "4px", marginTop: "28px" }}>
        <Input
          type="checkbox"
          className="form-check-Input"
          style={{ width: "25px", height: "25px", marginLeft: "38px" }}
          id="economy"
          name="economy"
          checked={validation.values.type === "economy"}
          onChange={handleCheckboxChange}
        />
        <Input
          type="checkbox"
          className="form-check-Input"
          style={{ width: "25px", height: "25px", marginLeft: "124px" }}
          id="gold"
          name="gold"
          checked={validation.values.type === "gold"}
          onChange={handleCheckboxChange}
        />
        <Input
          type="checkbox"
          className="form-check-Input"
          style={{ width: "25px", height: "25px", marginLeft: "90px" }}
          id="platinum"
          name="platinum"
          checked={validation.values.type === "platinum"}
          onChange={handleCheckboxChange}
        />
        <Input
          type="checkbox"
          className="form-check-Input"
          style={{ width: "25px", height: "25px", marginLeft: "122px" }}
          id="other"
          name="other"
          checked={validation.values.type === "other"}
          onChange={handleCheckboxChange}
        />
        <span
          style={{
            marginLeft: "590px",
            marginTop: "5px",
            display: "inline-block",
          }}
        >
          {totalData.lineTotal.toFixed(2)}
        </span>
      </div>
    </div>
  )
}

export default TabelFooter
