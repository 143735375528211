import React, { useState, createContext, useEffect } from "react"

const initialTabelAmount = 0
const initialTabelData = []
const initialActiveRow = 1
const initialFilledTabelData = []

export const PaymentSummaryContext = createContext({
  totalAmout: initialTabelAmount,
  tabelData: initialTabelData,
  activeRow: initialActiveRow,
  updateTabelDataHandler: data => {},
  updateSourceOfRecordHandler: (rowData, sourceOfRecord) => {},
  filledTabelData: initialFilledTabelData
})

let tabel = []
const PaymentSummaryContextProvider = props => {
  const [totalAmout, setTotalAmout] = useState(initialTabelAmount)
  const [tabelData, setTabelData] = useState(initialTabelData)
  const [activeRow, setActiveRow] = useState(initialActiveRow)
  const [filledTabelData, setFilledTabelData] = useState(initialFilledTabelData)
  const [paymentReset, setPaymentReset] = useState(false)

  // update active row
  useEffect(() => {
    if (tabelData.length && tabelData[activeRow - 1]) {
      if (
        // tabelData[activeRow - 1].aboveNo &&
        tabelData[activeRow - 1].amount &&
        tabelData[activeRow - 1].date &&
        tabelData[activeRow - 1].method 
        // tabelData[activeRow - 1].petId &&
        // tabelData[activeRow - 1].petName
      ) {
        if (
          tabelData[activeRow - 1].recivedNow === false &&
          // tabelData[activeRow - 1].ref !== "" &&
          tabelData[activeRow - 1].sourceOfRecord !== ""
        ) {
          setActiveRow(prevState => prevState + 1)
        }
        if (tabelData[activeRow - 1].recivedNow) {
          setActiveRow(prevState => prevState + 1)
        }
      }
    }
  }, [tabelData])

  const updateTabelDataHandler = data => {
    const updatedTabelData = [...tabel]
    const index = updatedTabelData.findIndex(
      one => one.rowNumber === data.rowNumber
    )

    if (index === -1) {
      updatedTabelData.push(data)
    } else {
      updatedTabelData[index] = data
    }

    setTabelData(updatedTabelData)
    tabel = updatedTabelData
    const totalAmountOfMoney = updatedTabelData.reduce((total, one) => {
      return total + +one.amount
    }, 0)
    setTotalAmout(totalAmountOfMoney)
  }

  const updateSourceOfRecordHandler = (rowData, sourceOfRecord) => {
    const updatedTabelData = [...tabel]
    const index = updatedTabelData.findIndex(
      one => one.rowNumber === rowData.rowNumber
    )

    if (index === -1) {
      return
    } else {
      updatedTabelData[index] = rowData
      updatedTabelData[index].sourceOfRecord = sourceOfRecord
      setTabelData(updatedTabelData)
      tabel = updatedTabelData
    }
  }
  useEffect(() => {
    const arr = []
    tabelData.forEach((one, index) => {
      if(one.amount !== "" && one.method !== "" && one.date !== "") {
        arr.push(one)
      }
    })
    setFilledTabelData(arr)
  }, [tabelData])

  const paymentResetHandler = (state) => {
    setPaymentReset(state)
    setActiveRow(1)
   }

  return (
    <PaymentSummaryContext.Provider
      value={{
        totalAmout,
        tabelData,
        activeRow,
        updateTabelDataHandler,
        updateSourceOfRecordHandler,
        filledTabelData,
        paymentReset,
        paymentResetHandler
      }}
    >
      {props.children}
    </PaymentSummaryContext.Provider>
  )
}

export default PaymentSummaryContextProvider
