import React from "react"
import { Navigate } from "react-router-dom"

import Invoice from "pages/add-invoice/invoice"
import Invoices from "pages/invoices-list/invoices"
import Paper from "pages/add-paper/index"


// Authentication related pages
import Login from "../pages/Authentication/Login"

// Comment by asmaa

// import Logout from "../pages/Authentication/Logout";
// import Register from "../pages/Authentication/Register";
// import ForgetPwd from "../pages/Authentication/ForgetPassword";

//Commented By Asmaa

// //  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login"
import Logout from "pages/Authentication/Logout"
import { privilegePageId } from "common/privilege"



const authProtectedRoutes = [
  { path: "/add-invoice", component: <Invoice />, pageId: privilegePageId.can_add_invoice },
  { path: "/add-paper", component: <Paper />, pageId: privilegePageId.can_add_document  },
  { path: "/invoices", component: <Invoices />, pageId: privilegePageId.can_view_invoices  },

  {
    path: "/",
    exact: true,
    pageId: privilegePageId.can_view_invoices,
    component: <Navigate to="/invoices" />,
  },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },

 
]

export { authProtectedRoutes, publicRoutes }
